
export default class Tools {
    static TOOL_ERASER = 'eraser';
    static TOOL_BRUSH = 'brush';
    static TOOL_PENCIL = 'pencil';
    static TOOL_FILL = 'fill';
    static TOOL_LINE = 'line';
    static TOOL_SQUARE = 'square';
    static TOOL_CIRCLE = 'circle';
}
